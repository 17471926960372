import { defineComponent, reactive, onMounted } from 'vue'
import './index.less'

export default defineComponent({
    setup () {
       
        return () => (
            <div class="personal-page">
                <div class="header-box flex-box justify-content-between plr35">
                    <div>翻腕隐私政策</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <div class="agree-info-box logoff">
                    <ul>
                        <li>
                            <div class="pb60">
                                欢迎您使用翻腕产品和服务！翻腕产品和服务是由上海往矣科技有限公司（注册地址：上海市奉贤区海坤路1号第2幢4560室）通过包括但
                                不限于翻腕网站（www.fanwoon.com）、翻腕App、相关微信开放平台账号或小程序以及随技术发展可能出现的其他新形态等途径向
                                您提供的产品和服务。我们非常重视您的隐私保护和个人信息保护，特制定本《隐私政策》帮助您了解以下内容：
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">一、引言</div>
                            <div class="pb60">
                                为了切实保护翻腕用户隐私权和个人信息，优化用户体验，翻腕根据《中华人民共和国个人信息保护法》《中华人民共和国网络安全法》《常见类型移动互联网应用程序必要个人信息范围规定》等法律法规，并参考《信息安全技术 个人信息安全规范》（GB/T 35273-2020）等国家标准，制定本《隐私政策》。本《隐私政策》适用于我们向您提供的所有服务，无论您是通过计算机设备、移动终端或其他设备获得的翻腕服务。
                            </div>
                            <div class="pb60">
                                本《隐私政策》将详细说明每个功能可能收集的个人信息范围、收集目的，收集方式，以及拒绝提供个人信息可能的影响。
                                <span class="under-line-font">
                                    请知悉，如拒绝提供非必要个人信息或附加功能所需信息时，您仍然能够使用翻腕基本功能。
                                </span>
                            </div>
                            <div class="pb60">
                                本《隐私政策》将向您说明翻腕平台可能需要调取的设备权限、调用的目的或其对应的业务功能、调用前是否询问以及用户关闭相应权限的方式，
                                <span class="under-line-font">
                                    请知悉，您同意本《隐私政策》后，相应设备权限并不会默认开启，当涉及重要或敏感的设备权限时，我们会在您使用到相应业务功能时，另行弹窗再次征得您的同意后开启
                                </span>
                                ，权限开启后，您还可以随时通过设备设置权限关闭，您不同意开启权限，将不会影响其他非相关业务功能的正常使用。
                            </div>
                            <div class="pb60">
                                <span class="bold-font">
                                    我们希望您在使用翻腕服务前仔细阅读并理解本《隐私政策》的所有内容，希望您可以根据自己的理解做出合适的选择
                                </span>
                                。此外，当我们将您的个人信息用于本《隐私政策》未涵盖的用途时，我们会事先征求您的同意。
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader"> 二、我们如何收集和使用您的个人信息</div>
                            <div class="logoHeader"> (一)基本功能及相关必要个人信息</div>
                            <div class="pb60">
                                在您使用翻腕提供的以下服务或功能过程中，我们将基于以下基本功能收集您的相关必要个人信息。
                            </div>
                            <div class="pb60">
                                1.账号注册、登录
                                <div>
                                    您首先需要注册一个翻腕账号成为翻腕的<span class="under-line-normal">注册用户</span>。当您注册时，您需要向我们提供您本人的<span class="under-line-normal">手机号码</span>，我们将通过发送短信验证码的方式来验证您的身份是否有效。注：您的手机号码在您没有主动或同意交换的情况下，不会展示给其他用户。
                                </div>
                            </div>
                            <div class="pb60">
                                2.完善在线简历
                                <div>
                                    对于求职者，在您注册成功后，首先您需要完善
                                    <span class="under-line-normal">
                                        在线简历，设置您的头像，填写您的姓名、性别、求职身份、参加工作时间、出生年月、最高学历、个人介绍、工作经历（公司名称、在职时间、职位名称、工作内容）、教育经历（学校、学历、专业、在校时间段）、求职期望（期望城市、职位偏好、薪资要求、期望行业）、求职状态。
                                    </span>
                                    我们将根据您的以上信息，为您匹配可能合适您的岗位，将您推荐给可能合适的招聘者。您的个人信息（包括真实手机号码）在您没有主动或同意交换的情况下，不会展示给其他用户。<span class="bold-font">请注意：若您主动投递某一职位，您的在线简历将会对其可见。</span>
                                </div>
                            </div>
                        </li>
                        <li class="pb60">
                            <div class="logoHeader">(二)附加功能及相关非必要个人信息</div>
                                <div class="pb60">
                                    1.招聘者身份认证
                                    <div>
                                        招聘者包括用人单位用户和人力资源服务机构用户（含猎头、人才经纪人等）。对于招聘者，您使用招聘服务之前，需通过实名认证与实人认证。您需要向我们提供您本人的身份信息用于身份核验，包括真实的姓名、<sapn class="bold-font">身份证号</sapn>、<span class="under-line-font">人脸图像及视频</span>（实现核验功能后即删除）；同时完善您的<span class="under-line-font">工作信息</span>，包括您<span class="under-line-font">工作单位的名称、职位头衔</span>，以证明您可以代表该单位进行招聘，保证招聘的真实性，维护求职者合法权益。如果您提交的单位系首次在翻腕上发布招聘信息，那么您还需要提供营业执照以验证招聘单位的合法性。如您为人力资源服务机构的招聘者用户，并为其他企业代招，您还需要向我们另行提供《人力资源服务许可证》和代招证明资料，以证明您所在的单位符合资质要求并有权为其他企业招聘。认证通过后，您发布的职位方可正常对外显示。
                                    </div>
                                </div>
                                <div class="pb60">
                                    2.查找附近职位等与位置有关的服务
                                    <div>
                                        在查看或推荐附近职位、填写职位及目标地址、选择附近地铁站、企业身份认证、招聘者地址核验、发布或修改招聘信息等场景中，我们会收集您的位置信息，以更好地为您提供求职、招聘功能以及与位置有关的产品或服务。我们会通过位置权限弹窗申请获取您的<span class="bold-font">位置信息（GPS定位）</span>；经您授权同意后，再获取您的位置权限，例如您所在的<span class="under-line-font">城市、地区</span>等。我们承诺仅会在您主动使用相关功能时获取您的位置信息，仅按照提供相关服务所需的最低频率收集您的位置信息，不会追踪您的行踪轨迹。如果拒绝授权，您可以选择手动输入目标求职地点或使用不需要位置信息的招聘或求职功能，但不影响您使用不基于位置的其他服务。
                                    </div>
                                </div>
                                <div class="pb60">
                                    3.在线支付
                                    <div>
                                    在您选择在线支付购买我们的邀约面试、发放推荐奖金等增值服务时，您可以选择第三方支付机构所提供的支付服务。支付功能中，我们会从第三方支付机构获取您的ID、订单交易结果，同时我们需要将您的<span class="bold-font">购买明细、订单号与交易金额信息</span>与这些支付机构共享以实现其确认您的支付指令并完成支付。
                                    </div>
                                </div>
                                <div class="pb60">
                                    4.约面试
                                    <div>
                                    当您在线上与对方达成面试意向，并通过翻腕提供的约面试功能成功发出面试邀请后，您的<span class="under-line-font">手机号码</span>需要向对方进行展示，避免对方在前往面试地点时无法与您取得联系。
                                    </div>
                                </div>
                                <div class="pb60">
                                    5.求职安全及服务质量保障
                                    <div>
                                        为了增强翻腕平台的求职招聘、在线邀约面试等过程中的安全性，尽可能保障您或其他用户在招聘求职过程中的人身、财产安全免遭侵害，以及保障我们向您提供服务的质量，我们会收集您的以下个人信息：
                                    </div>
                                    <div>
                                        (1) 我们仅会基于处理您的投诉举报、申诉、出于提升平台安全性的考虑，使用您在商务场景下产生的<span class="under-line-font">通讯信息/面试记录</span>，包括您的<span class="under-line-font">手机号码</span>、<span class="bold-font">沟通记录</span>（您知悉并同意，您与翻腕人工客服的通话可能会被<span class="bold-font">录音</span>），以及您的身份信息，如<span class="under-line-font">账号ID、手机号码</span>、<span class="bold-font">身份证号</span>，以处理投诉举报事项和纠纷。如您为招聘者，我们可能还将使用您的<span class="bold-font">面部识别信息</span>（实现核验功能后即删除）对您进行身份核验、安全检测等。
                                    </div>
                                    <div>
                                        (2)在我们检测到您的账号和行为存在异常或违规，以及您被其他用户举报时，我们可能会要求您提供您的<span class="bold-font">姓名</span>和<span class="bold-font">身份证号</span>，以验证当前用户是否为您本人。如您拒绝提供上述信息进行身份核验，您将无法正常使用我们的产品及/或服务。
                                    </div>
                                    <div>
                                        (3)在您授权允许我们打开您的位置权限后，为发现、定位和排查行为异常，我们需要获取或更新您的<span class="under-line-font">设备标识信息</span>、<span class="bold-font">位置信息、操作行为</span>。为了求职者的人身、财产安全，我们将在招聘者身份认证（环境认证）、地址核验、发布/修改职位、通过PC端登录等场景中，收集招聘者的<span class="bold-font">位置信息</span>以核验人企关系，防止作弊风险，保障招聘求职安全。
                                    </div>
                                    <div>
                                        (4)为了保障软件与服务的安全运行、运营的质量及效率，我们会在您登录、使用过程中，收集您的设
                                        <span class="under-line-font">
                                            备信息（包括设备标识信息（IMEI/MEID/ESN、IMSI、IDFA、OAID、SIM卡序列号、硬件序列号、Android_id）、IP地址、MAC地址、WIFI信息（BSSID、SSID）、网络相关信息（网络接入方式、类型、状态等）、应用信息（指定应用信息、正在运行的进程信息及其他应用相关信息）、传感器信息、设备参数及系统信息（设备型号、操作系统及硬件相关信息）
                                        </span>
                                        。在安全监测场景下，我们会调用设备的<span class="bold-font">加速度传感器</span>，用以检测设备的动/静状态，排查设备异常使用情况，确保产品和服务的运行和运营安全。
                                    </div>
                                    <div>
                                        (5)为了预防恶意程序、保障运营质量及效率，我们会收集您的服务日志信息（包括您的操作使用信息，如浏览记录、点击记录、搜索查询记录、收藏记录、发布信息、分享信息，以及操作日期和时间）。
                                    </div>
                                    <div>
                                        (6)如您为招聘者，为了便于定位并解决您反馈的软件问题，保障服务质量，当您通过特定入口或在线客服向我们反馈问题时，我们会收集您的服务日志信息以及您在商务场景下产生的其他数据（如通讯信息）。
                                    </div>
                                    <div>
                                    (7)为了验证您的身份信息，防止他人未经授权访问您的账号，我们会在您授权同意后，访问您的<span class="under-line-font">设备信息、微信OpenID</span>。如您为招聘者，我们可能还将核验您的<span class="bold-font">面部识别信息</span>（实现核验功能后即删除）。若您不同意进行上述相应的身份验证，我们可能无法利用身份信息验证的方式保障您的账号安全。
                                    </div>
                                </div>
                                <div class="pb60">
                                    6.活动参与
                                    <div>
                                    当您选择参加平台不时推出的各类活动（包括但不限于抽奖活动、任务活动、问卷调查等）时，可能需要您提供相关的个人信息，具体内容以届时公示的活动规则为准。如果您在活动中获奖，为了向您邮寄奖品，您需要向我们或活动主办方（如有）提供<span class="bold-font">姓名、联系方式、快递地址</span>。
                                    </div>
                                </div>
                                <div class="pb60">
                                7.其他
                                    <div>
                                    （1）实现统计分析：我们会采取脱敏、去标识化/匿名化处理等不识别您个人身份的前提下对您的简历信息或发布的招聘信息进行综合统计、分析和加工，以帮助您更加便捷地了解职业信息、就业趋势、招聘趋势、薪资待遇、供需情况等信息，以及便于我们分析、评估、改善和提升我们的服务质量和运营活动。
                                    </div>
                                    <div>
                                    （2）机器学习、模型训练：在经安全加密技术处理严格去标识化且无法识别特定个人的前提下，我们会将收集的数据用于机器学习、模型训练，以开发新服务和改进现有服务，提升用户体验和服务质量。
                                    </div>
                                </div>
                        </li>
                        <li>
                            <div class="logoHeader">（三）需要您授权同意调取系统权限的情形</div>
                            <div>1.基于保护招聘求职过程中的求职者人身安全之考虑，以及满足互联网平台服务普遍存在的风控需求，翻腕的附加业务功能可能需要您事先同意我们调取相关的系统权限以收集和使用您的个人信息。</div>
                            <div>2.若您在手机的相关设置功能，关闭上述系统权限，即代表您撤回这些授权，我们将不再继续收集和使用您的这些个人信息，但也无法为您提供与这些授权所对应的相关功能。上述权限的关闭决定并不会影响此前基于您的授权所进行的个人信息的处理。</div>
                            <div class="one-table">
                                <div class="one-table-content">
                                    <table class="fanwoon-table">
                                        <thead>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>A</td>
                                                <td>B</td>
                                                <td>C</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>权限名称</td>
                                                <td>权限功能说明</td>
                                                <td>使用场景或目的</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>拍摄使用</td>
                                                <td>拍摄照片</td>
                                                <td>拍摄并上传照片作为用户头像</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>访问精准定位</td>
                                                <td>通过全球定位系统（GPS）或网络位置信息（例如基站和WLAN）获取精准地理位置信息</td>
                                                <td>用于为求职者推荐附近的职位；保障招聘者身份和职位信息真实有效，确保招聘求职安全</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>访问粗略位置</td>
                                                <td>通过网络位置信息（例如基站和WLAN）获取大致地理位置信息</td>
                                                <td>用于为求职者推荐附近的职位；保障招聘者身份和职位信息真实有效，确保招聘求职安全</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>读取手机状态(设备 IMSI/IMEI 号）</td>
                                                <td>提供读取手机设备标识等信息，请您放心该权限无法监听、获取您的任何通话内容与信息</td>
                                                <td>用于安全风控的需求、确保账号安全</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>读取外置存储器</td>
                                                <td>提供读取手机储存空间内数据的功能</td>
                                                <td>允许App读取存储中的图片、文件等内容，主要用于帮助您发布信息，在本地记录崩溃日志信息（如有）等功能</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>写入外置存储器</td>
                                                <td>提供写入外部储存功能</td>
                                                <td>允许App写入/下载/保存/修改/删除图片、文件、崩溃日志等信息</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">iOS操作系统应用权限列表</div>
                        </li>
                        <li>
                            <div class="pb60">
                                <div class="one-table">
                                    <div class="one-table-content">
                                        <table class="fanwoon-table">
                                            <thead>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>A</td>
                                                    <td>B</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>权限功能说明</td>
                                                    <td>使用场景或目的</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>使用摄像头</td>
                                                    <td>拍摄并上传照片作为用户头像</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>访问一次位置</td>
                                                    <td>用于为求职者推荐附近的职位；保障招聘者身份和职位信息真实有效，确保招聘求职安全</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>仅在使用期间访问位置</td>
                                                    <td>用于为求职者推荐附近的职位；保障招聘者身份和职位信息真实有效，确保招聘求职安全</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>访问相册</td>
                                                    <td>用于支持存储中图片和视频的访问、发送、上传</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>获取设备信息</td>
                                                    <td>用于确保您的账号安全以及广告来源统计</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">（四）需要您授权的其他情形</div>
                            <div class="pb60">
                                <div>1.我们可能会将来自某项服务的信息与来自其他服务所获得的信息结合起来进行综合统计分析，用于为您提供更加精准、个性的产品、服务及/或平台活动。</div>
                                <div>2.邀请您参与有关我们产品、服务的调查或某些您可能感兴趣的平台活动等，以评估、改进我们平台的服务效果，为您呈现更佳的服务体验。如您不希望接收此类邀请信息，您可以按照我们提示的方式选择退订。</div>
                                <div>3.经您同意或授权的或法律法规允许的其他用途。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">（五）征得授权同意的例外</div>
                            <div class="pb60">
                                <div>根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：</div>
                                <div>(1)与我们履行法律法规规定的义务相关的；</div>
                                <div>(2)与国家安全、国防安全直接相关的；</div>
                                <div>(3)与公共安全、公共卫生、重大公共利益直接相关的；</div>
                                <div>(4)与刑事侦查、起诉、审判和判决执行等直接相关的；</div>
                                <div>(5)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</div>
                                <div>(6)所收集的个人信息是您自行向社会公众公开的；</div>
                                <div>(7)从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</div>
                                <div>(8)根据您的要求签订和履行合同所必需的；</div>
                                <div>(9)学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</div>
                                <div>(10)用于维护翻腕平台产品和/或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；</div>
                                <div>(11)法律行政法规规定的其他情形；</div>
                                <div>
                                    如果您对我们收集和使用您的个人信息有任何疑问或需要提供进一步的信息，请通过本《隐私政策》公布的联系方式与我们联系。
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">三、我们如何使用cookie和同类技术</div>
                            <div class="pb60">
                                为确保网站正常运转，我们会在您的计算机或移动设备上存储名为cookie的数据文件。cookie通常包含用户身份标识符、城市名称以及一些字符。cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用cookie技术，我们能够为您提供更加周到的服务。我们不会将cookie用于本《隐私政策》所述目的之外的任何用途。您可根据自己的偏好管理或删除cookie。有关详情，请参见<span class="blue-font">aboutcookies.org</span>。您可以清除计算机上保存的所有cookie，大部分网络浏览器都设有阻止cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于cookie的翻腕提供的服务或功能。您可以通过更改您的浏览器设置限制翻腕对cookie的使用。以chrome浏览器为例，您可以在chrome浏览器右上方的下拉菜单的“浏览器设置”中，通过“设置-高级-清除浏览数据”，选择清除您的cookie。
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">四、我们如何共享、转让、公开披露您的个人信息</div>
                            <div class="pb60">
                            我们不会向第三方共享、转让或未经授权擅自公开披露您的个人信息，除非经过您本人事先授权同意，或者共享、转让、公开披露的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(一)共享</div>
                            <div class="pb60">
                                <div>
                                我们会以高度的勤勉义务对待您的信息。除以下情形外，未经您同意，我们不会与除翻腕的关联公司外的任何第三方公司、组织和个人分享您的信息：
                                </div>
                                <div>
                                1.为实现特定功能而与业务合作伙伴共享
为保障翻腕APP的稳定运行或实现相关功能，我们可能会使用关联APP提供的服务，或者接入由第三方提供的软件开发包（SDK）及其他第三方供应商提供的产品实现前述目的。
                                </div>
                                <div class="logoHeader">翻腕第三方共享清单：</div>
                                <div class="big-table">
                                    <div class="big-table-content">
                                        <table class="fanwoon-table2">
                                            <tr>
                                                <td>第三方说明</td>
                                                <td>第三方主体</td>
                                                <td>SDK用途</td>
                                                <td>处理个人信息类型</td>
                                                <td>数据处理方式</td>
                                                <td>官网链接</td>
                                                <td>隐私政策链接</td>
                                            </tr>
                                            <tbody>
                                                <tr>
                                                    <td>极光推送SDK</td>
                                                    <td>深圳市和讯华谷信息技术有限公司</td>
                                                    <td>为 APP 用户提供信息推送服务</td>
                                                    <td>
                                                    设备参数及系统信息(设备类型、设备型号、系统版本、及相关硬件信息)：用于识别用户的设备类型、设备型号、系统版本等，确保消息准确下发；
        设备标识符（IMEI、IDFA、Android ID、GID、 MAC、OAID、VAID、AAID、IMSI、MEID、UAID、SN、ICCID、SIM信息）：用于识别唯一用户，保证推送的精准送达及推送信息的准确统计；
        网络信息(IP 地址、WiFi 信息、基站信息、DNS地址、DHCP地址、SSID、BSSID)与位置信息（经纬度）：用于优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性，同时实现区域推送功能；
        应用列表信息(应用崩溃信息、通知开关状态、APP 应用列表及活跃状态、APP 应用页面信息、APP 功能事件相关信息)：当一个设备有多个 APP 的推送链路同时活跃时，我们采用合并链路技术，随机合并成一条链路，以达到为用户节省电省流量的目的；
        推送日志信息：以便开发者查询使用推送服务记录，了解推送信息送达的情况，调整推送策略。
                                                    </td>
                                                    <td>通过去标识化、加密传输及其他安全方式</td>
                                                    <td class="blue-font">https://www.jiguang.cn/push</td>
                                                    <td class="blue-font">https://www.jiguang.cn/license/privacy</td>
                                                </tr>
                                                <tr>
                                                    <td>小米推送SDK</td>
                                                    <td>北京小米移动软件有限公司</td>
                                                    <td>在小米手机终端推送消息时使用</td>
                                                    <td>设备标识符（如 Android ID、OAID、GAID）、设备信息</td>
                                                    <td>通过去标识化、加密传输及其他安全方式</td>
                                                    <td class="blue-font">https://dev.mi.com/console/appservice/push.html</td>
                                                    <td class="blue-font">https://dev.mi.com/console/doc/detail?pId=1822</td>
                                                </tr>
                                                <tr>
                                                    <td>华为 HMS SDK</td>
                                                    <td>华为软件技术有限公司</td>
                                                    <td>在华为手机终端推送消息时使用</td>
                                                    <td>应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息</td>
                                                    <td>通过去标识化、加密传输及其他安全方式</td>
                                                    <td class="blue-font">https://developer.huawei.com/consumer/cn/</td>
                                                    <td class="blue-font">https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177</td>
                                                </tr>
                                                <tr>
                                                    <td>荣耀推送SDK</td>
                                                    <td>荣耀终端有限公司</td>
                                                    <td>在荣耀手机终端推送消息时使用</td>
                                                    <td>应用匿名标识 (AAID)，应用 Token</td>
                                                    <td>通过去标识化、加密传输及其他安全方式</td>
                                                    <td class="blue-font">https://developer.hihonor.com/cn/promoteService</td>
                                                    <td class="blue-font">https://www.hihonor.com/cn/privacy/privacy-policy/</td>
                                                </tr>
                                                <tr>
                                                    <td>OPPO推送SDK</td>
                                                    <td>广东欢太科技有限公司</td>
                                                    <td>在 OPPO 手机终端推送消息时使用</td>
                                                    <td>设备标识符（如 IMEI、ICCID、IMSI、Android ID、GAID）、应用信息（如应用包名、版本号和运行状态）、网络信息（如 IP 或域名连接结果，当前网络类型）</td>
                                                    <td>通过加密传输和处理的安全处理方式</td>
                                                    <td class="blue-font">https://open.oppomobile.com/new/introduction?page_name=oppopush</td>
                                                    <td class="blue-font">https://open.oppomobile.com/wiki/doc#id=10288</td>
                                                </tr>
                                                <tr>
                                                    <td>VIVO推送SDK</td>
                                                    <td>广东天宸网络科技有限公司及将来受让运营 vivo 开放平台的公司</td>
                                                    <td>在 vivo 手机终端推送消息时使用</td>
                                                    <td>设备信息</td>
                                                    <td>通过去标识化、加密传输及其他安全方式</td>
                                                    <td class="blue-font">https://dev.vivo.com.cn/promote/pushNews</td>
                                                    <td class="blue-font">https://www.vivo.com.cn/about-vivo/privacy-policy</td>
                                                </tr>

                                                <tr>
                                                    <td>魅族推送SDK</td>
                                                    <td>珠海市魅族通讯设备有限公司</td>
                                                    <td>在魅族手机终端推送消息时使用</td>
                                                    <td>设备标识信息、位置信息、网络状态信息、运营商信息</td>
                                                    <td>通过去标识化、加密传输及其他安全方式</td>
                                                    <td class="blue-font">https://open.flyme.cn/service?type=push</td>
                                                    <td class="blue-font">https://www.meizu.com/legal.html</td>
                                                </tr>
                                                <tr>
                                                    <td>微信开放平台SDK</td>
                                                    <td>深圳市腾讯计算机系统有限公司</td>
                                                    <td>用于帮助用户将内容分享至微信、支持微信支付</td>
                                                    <td>应用信息</td>
                                                    <td>通过去标识化、加密传输及其他安全方式</td>
                                                    <td class="blue-font">https://open.weixin.qq.com/</td>
                                                    <td class="blue-font">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div>
                                    为了进行广告推送、投放与分析，我们可能会与提供相关服务的第三方合作伙伴共享去标识化的设备信息，以帮助其在不识别您个人身份的前提下提升广告有效触达率，并帮助我们分析、衡量广告和相关服务的有效性。同时，为分析应用下载、激活等情况的需要，基于您的同意，我们可能会获得您在使用第三方合作伙伴服务时所产生的设备信息，请您仔细阅读第三方合作伙伴的用户协议或隐私政策。此外，我们也将会严格遵守相关法律法规的规定，并要求第三方保障其提供的信息的合法性。
                                </div>
                                <div>
                                    我们接入的第三方可能涉及收集用户信息（包括但不限于尝试写入SD卡数据，具体可点击查看上述第三方共享清单），用于向用户提供服务。我们会对第三方提供的服务进行检测和评估，并严格遵守相关法律法规和监管要求。
    为了向用户提供更多服务，保证服务稳定性和质量，或升级相关功能，我们可能需要调整我们接入的第三方服务。
    我们会及时在本页面向您说明收集用户信息的第三方服务的最新情况，第三方服务可能因为其版本升级、策略调整等原因导致数据处理存在一定的变化，您可到第三方服务官方页面查看。
                                </div>
                                <div>
                                我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，以展示我们的产品或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
                                </div>
                                <div>
                                我们承诺在共享过程中，尽最大可能保障您的数据和隐私不受侵害，并以不低于本《隐私政策》所要求的保密和安全措施来处理该些信息。如果您拒绝本条款的内容，请通过本《隐私政策》公布的联系方式与我们联系。
                                </div>

                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(二)转让</div>
                            <div class="pb60">
                                <div>我们不会将您的个人信息转让给除翻腕关联公司外的任何公司、组织和个人，但以下情形除外：</div>
                                <div>1.事先获得您的明确授权或同意；</div>
                                <div>2.满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；</div>
                                <div>3.如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(三)公开披露</div>
                            <div class="pb60">
                                <div>我们仅会在以下情形下，公开披露您的个人信息：</div>
                                <div>1.获得您的明确同意；</div>
                                <div>2.基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(四)例外情形</div>
                            <div class="pb60">
                                <div>在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</div>
                                <div>1.与我们履行法律法规规定的义务相关的；</div>
                                <div>2.与国家安全、国防安全直接相关的；</div>
                                <div>3.与公共安全、公共卫生、重大公共利益直接相关的；</div>
                                <div>4.与刑事侦查、起诉、审判和判决执行等直接相关的；</div>
                                <div>5.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</div>
                                <div>6.您自行向社会公众公开的个人信息；</div>
                                <div>7.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</div>
                                <div>8.根据个人信息主体要求签订和履行合同所必需的；</div>
                                <div>9.用于维护所提供的产品或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；</div>
                                <div>10.法律法规规定的其他情形。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">五、我们如何保存及保护您的个人信息</div>
                            <div class="pb60">
                                <div class="logoHeader">(一)保存信息</div>
                                <div>1.信息存储</div>
                                <div>您在使用翻腕产品及服务期间，我们将持续为您保存您的个人信息。如果您注销账号或主动删除上述信息，我们会及时删除您的个人信息或进行匿名化处理，因法律规定需要留存个人信息的，我们不会再将其用于日常业务活动中。</div>
                                <div>2.保存地域</div>
                                <div>我们承诺，您的个人信息将只存储在位于中华人民共和国境内的服务器上，您的信息不会被我们主动传输到境外。如在符合适用法律规定的情形下因业务需要向境外传输个人信息的，我们会事先征得您的同意，向您告知用户信息出境的目的、接收方、安全保障措施、安全风险等情况，并依法开展安全评估。</div>
                                <div>3.例外情况</div>
                                <div>一般而言，我们仅为实现目的所必需的合理时间保留您的个人信息，超出保留时间后，我们会根据适用法律的要求及时删除您的个人信息或进行匿名化处理。下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：</div>
                                <div>（1）为遵守法律法规等有关规定的适用；</div>
                                <div>（2）为遵守法院判决、裁定或其他法律程序的规定；</div>
                                <div>（3）为遵守相关政府机关或法定授权组织的要求；</div>
                                <div>（4）为保护我们及我们的客户、用户或雇员的人身财产安全；</div>
                                <div>5）其他合法权益所合理必需的用途。</div>
                                <div>4.当我们的产品或服务发生停止运营的情形时，我们将按照法律规定向用户进行通知，通知方式包括但不限于消息推送、平台公告等，并在合理期限内删除或匿名化处理您的个人信息。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(二)保护措施</div>
                            <div class="pb60">
                                <div>1.我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对翻腕网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全培训课程，加强员工对于保护个人信息重要性的认识。</div>
                                <div>2.我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本《隐私政策》所述目的所需的期限内保留您的个人信息（除非法律有强制的存留要求或双方另有约定）。</div>
                                <div>3.互联网并非绝对安全的环境，使用翻腕平台服务时，我们强烈建议您不要使用非翻腕平台推荐的通信方式发送您的信息。您可以通过我们的服务与他人建立联系和相互分享内容。当您通过我们的服务进行沟通交流、分享内容时，您可以自主选择沟通、分享的对象，作为能够看到您的联络方式、交流信息等相关信息的第三方。</div>
                                <div>4.在使用翻腕服务进行沟通时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账号发生泄露，请您立即通过本《隐私政策》的“十、如何联系我们”中公布的联系方式与我们联系，以便我们根据您的申请采取相应措施。</div>
                                <div>请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评论、沟通时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。请妥善保管账号，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(三)安全事件通知</div>
                            <div class="pb60">
                                <div>1.我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。</div>
                                <div>2.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时通过软件内部提醒、邮件、短信或电话等方式向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况通过上述方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">六、您的权利</div>
                            <div class="pb60">
                            按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(一)访问和修改您的个人信息</div>
                            <div class="pb60">
                                <div>1.访问修改姓名</div>
                                <div>（1）求职者可通过我的&gt;简历&gt;姓名修改您的姓名；</div>
                                <div>（2）招聘者可通过我的&gt;个人信息&gt;姓名修改您的姓名。</div>
                                <div>2.访问和修改性别</div>
                                <div>求职者可以通过我的&gt;简历&gt;性别更改性别；</div>
                                <div>招聘者可通过我的&gt;个人信息&gt;姓名修改您的姓名。</div>
                                <div>3.访问和修改简历内容</div>
                                <div>求职者可以在我的&gt;简历中修改简历内容；</div>
                                <div>4.访问和修改头像</div>
                                <div>求职者可以在我的&gt;简历&gt;点击头像中查看和修改；</div>
                                <div>招聘者可以在我的&gt;个人信息&gt;头像中查看和修改。</div>
                                <div>5.如果您无法通过上述链接访问该等个人信息，您可以通过本《隐私政策》公布的联系方式与我们联系。</div>
                                <div>6.您在使用我们的产品和服务过程中产生的其他个人信息，对于您的合理请求，我们会相应向您提供。如果您想访问您在使用我们的产品和服务过程中产生的其他个人信息，请通过本《隐私政策》的“十、如何联系我们”中公布的联系方式与我们联系。</div>
                                <div>7.您发现我们处理的关于您的个人信息有错误时，您有权对错误或不完整的信息作出更正或更新，您可以通过上述段落中列明的方式提出更正或补充申请。为保障安全，我们将在您行使更正权前对您的身份进行验证。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(二)删除您的个人信息</div>
                            <div class="pb60">
                                <div>您可以通过“1.访问和修改您的个人信息”中列明的方式删除您的部分信息。</div>
                                <div>在以下情形，您可以通过本《隐私政策》公布的联系方式与我们联系，向我们提出删除您的个人信息的请求：</div>
                                <div>1.如果我们违反法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息。</div>
                                <div>2.如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。</div>
                                <div>3.我们没有征求您的明确同意，收集了您的个人信息。</div>
                                <div>4.已实现个人信息处理目的、无法实现或者实现处理目的不再必要。</div>
                                <div>5.您注销了翻腕账号。</div>
                                <div>6.我们停止对您提供服务。</div>
                                <div>此外，法律法规另有规定，或达到与您约定或者个人信息处理规则明确的存储期限，或您撤回同意、注销账号等情形下，我们会遵照删除制度，采取措施对您的信息进行删除。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(三)注销您的账号</div>
                            <div class="pb60">
                            您可以通过我的&gt;注销账号中直接申请注销账号，也可以通过本《隐私政策》公布的联系方式，申请注销翻腕账号。我们会立即响应您的注销申请，并在您符合注销条件的情况下，在15日内完成注销。注销申请可在操作成功后的15日内撤回，具体操作流程及提示注意等相关内容以翻腕App中的《注销协议》载明的为准。在您主动注销账号之后，我们将停止为您提供产品或服务，并根据法律的要求删除您的个人信息，或对其进行匿名化处理，因法律规定需要留存个人信息的，我们不会再将其用于日常业务活动中。
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(四)提前获知产品和服务停止运营</div>
                            <div class="pb60">
                            翻腕愿一直陪伴您，若因特殊原因导致翻腕平台被迫停止运营，我们将按照法律法规的要求，在产品和/或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(五)您的其他权利</div>
                            <div class="pb60">
                            在法律允许的情况下，您可以复制您的个人信息，并请求将您的个人信息转移至指定的个人信息处理者，您还可以要求我们解释对您的个人信息处理规则。您可以通过本政策公布的联系方式与我们联系行使上述权利。
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(六)响应您的上述请求</div>
                            <div class="pb60">
                                <div>1.为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求，我们会在收到您的请求并验证您的用户身份后的15个工作日内响应您的上述请求。</div>
                                <div>2.对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">(七)响应请求的例外</div>
                            <div class="pb60">
                                <div>在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：</div>
                                <div>1.与我们履行法律法规规定的义务相关的</div>
                                <div>2.与国家安全、国防安全直接相关的；</div>
                                <div>3.与公共安全、公共卫生、重大公共利益直接相关的；</div>
                                <div>4.与刑事侦查、起诉、审判和执行判决等直接相关的；</div>
                                <div>5.证据表明您可能存在明显恶意、滥用权利及占用平台资源、辱骂客服人员等相关情形的；</div>
                                <div>6.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</div>
                                <div>7.您的请求超出了一般技术手段和商业成本可覆盖的范围；</div>
                                <div>8.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</div>
                                <div>9.涉及商业秘密的。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">七、我们如何处理未成年人的个人信息</div>
                            <div class="pb60">
                            翻腕非常重视对未成年人信息的保护。基于我们的产品、网站和服务的性质，我们不会故意从16岁以下的人士收集或维护我们产品和服务上的个人信息，并且我们产品和服务的任何部分都不会针对16岁以下的人士。如果您未满16岁，请不要以任何方式使用或访问我们的产品和服务。当我们得知我们无意中收集了16岁以下的人士的个人信息时，我们会根据适用法律法规进行删除或采取其他合适的措施。
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">八、我们对去世用户的个人信息保护</div>
                            <div class="pb60">
                            翻腕将从2021年11月1日起，根据《中华人民共和国个人信息保护法》的相关规定加强对死者个人信息的保护。翻腕用户（仅限自然人）去世后，其近亲属为了自身的合法、正当利益，可以通过本政策第十条公布的联系方式与我们联系，对去世用户的相关个人信息行使查阅、复制、更正、删除等权利，但是去世用户生前另有安排的除外。
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">九、本《隐私政策》的更新</div>
                            <div class="pb60">
                                <div>我们可能适时修订本《隐私政策》内容。如该等变更会导致您在本《隐私政策》项下权利的实质变化，我们将在变更生效前，通过系统推送、电子邮件、短信或页面公告等方式通知您。</div>
                                <div>若您不同意该等变更应停止使用翻腕平台产品和服务，或通过本《隐私政策》公布的联系方式要求暂时封停您的账号。<span class="bold-font">若您继续使用我们的产品或服务，即表示您同意受修订后的本《隐私政策》的约束。</span></div>
                                <div>本《隐私政策》所指的实质变化包括但不限于：</div>
                                <div>1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</div>
                                <div>2.个人信息共享、转让或公开披露的主要对象发生变化；</div>
                                <div>3.您参与个人信息处理方面的权利及其行使方式发生重大变化；</div>
                                <div>4.其他可能对您的个人信息权益产生重大影响的变化时；</div>
                                <div>5.个人信息出境情况发生变更时。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">十、如何联系我们</div>
                            <div class="pb60">
                                <div>如果您对本《隐私政策》及我们对您的个人信息的处理有任何疑问、意见、建议，或您发现可能存在个人信息被泄露的情形，请通过以下方式与我们联系：</div>
                                <div>1.您可以通过邮箱：<span class="blue-font">pip@tueneitui.com</span>与我们联系。</div>
                                <div>2.您可以关注微信公众号：「翻腕」与我们联系 。</div>
                                <div>对于属于与本政策或个人信息保护有关的问题，我们会在收到您的信息后15个工作日内对您的请求予以答复。</div>
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">十一、适用法律</div>
                            <div class="pb60">
                            本《隐私政策》与《翻腕用户协议》共同构成您使用翻腕服务的基本协议文件。本《隐私政策》适用中华人民共和国现行法律法规。
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">附录：定义</div>
                            <div class="pb60">
                                <div>本《隐私政策》中使用的特定词语，具有如下含义：</div>
                                <div>1.“我们”或“翻腕”，指上海往矣科技有限公司。</div>
                                <div>2.“您”或“用户”，指使用上海往矣科技有限公司运营的平台产品或服务的注册用户以及收费服务的购买方。</div>
                                <div>3.“翻腕平台”或“翻腕软件”，指<span class="blue-font">www.fanwoon.com</span>、翻腕手机客户端、翻腕微信小程序。</div>
                                <div>4.“个人信息”，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于个人敏感信息。</div>
                                <div>5.“个人信息主体”，指个人信息所标识的自然人。</div>
                                <div>6.“个人敏感信息”，指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括个人财产信息、个人健康生理信息、个人生物识别信息。</div>
                                <div class="bold-font">7.“位置信息”：是指已授权的GPS位置、WiFi信息（SSID、BSSID及网络质量和状态数据）。</div>
                                <div>8.“去标识化”，指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。</div>
                                <div>9.“匿名化”，指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
})